import firebase from "firebase/app"
import React from "react"
import {Provider} from "react-redux"
import {ReactReduxFirebaseProvider} from "react-redux-firebase"
import {createFirestoreInstance} from "redux-firestore"
import {ThemeProvider} from "styled-components"
import createStore from "./src/store"
import theme from "./src/theme"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({element}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()

  const firebaseConfig = {
    apiKey: "AIzaSyDiTbXJBmmovvups_xcTVp5DD3hS_SGfRs",
    authDomain: "nice-prototypes.firebaseapp.com",
    databaseURL: "https://nice-prototypes.firebaseio.com",
    projectId: "nice-prototypes",
    storageBucket: "nice-prototypes.appspot.com",
    messagingSenderId: "659929839839",
  }

  const rrfConfig = {
    userProfile: "users",
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  }

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance, // <- needed if using firestore
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }

  firebase.firestore()

  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}
