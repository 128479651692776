import {
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from "redux"
import {firebaseReducer} from "react-redux-firebase"
import {firestoreReducer} from "redux-firestore"
import {composeWithDevTools} from "redux-devtools-extension"
import projects from "./projects"

const reducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  projects,
})

const initialState = {}

const createStore = () =>
  reduxCreateStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware())
  )
export default createStore
